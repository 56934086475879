.App {
  text-align: center;
}

.main-color {
  background-color: #e9edee;
}


.navbar {
  background-image: url(./images/silver.jpg);
}


.cursive {
  font-family: 'Bad Script', cursive;
}

.enter-btn {
  height: 65px;
  width: 150px;
  font-size: 2em;
  position: relative;
  bottom: 5%;
  left: 45%;
}

.homepage-hero {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  background-image: linear-gradient(
    rgba(173, 180, 180, 0.7),
    rgba(202, 217, 217, 0.7)
  );
  opacity: var(--mainOpacity);
  height: 25em !important;
  width: 100%;
}

.hero-text {
  font-size: 2rem;
  margin: 0 2em;
}

#emailListSignup,
#emailListSignupName {
  width: 300px;
}

.gallery {
  -webkit-column-count: 3;
  -moz-column-count: 3;
  -column-count: 3;
  -webkit-column-width: 33%;
  -moz-column-width: 33%;
  -column-width: 33%;
  padding: 0px;
}

.gallery p {
  font-size: 2em;
}

.gallery .pics {
  -webkit-transition: all 350ms ease;
  transition: all 350ms ease;
  cursor: pointer;
  margin-bottom: 12px;
}

.gallery .pics:hover {
  filter: opacity(0.8);
}

.connect {
  font-size: 5em;
}
.contact-container {
  background-image: url(./images/girl-and-horse.jpg);
  opacity: 0.6;
}

.contact-form {
  height: 100vh;
  background-color: #f2f8f8;
  opacity: 0.5;
}

.contact-form:hover {
  opacity: 0.9;
}

.contact-label {
  font-size: 1.5rem;
}

.contact-icon {
  height: 50px;
  width: 50px;
}

.about-container {
  width: 90%;
  padding: 2%;
  margin: 1em 0 0 2em;
  display: flex;
  flex-wrap: wrap;
}

.about-section-image {
  max-width: 100%;
  max-height: 90%;
  padding-top: 2.25em;
}

.about-emphasis {
  font-size: 2em;
}

.about-section-banner {
  font-size: 2em;
  margin-top: 40px;
}

.lightbox {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: black;
}

.lightbox-close-btn {
  position: absolute;
  bottom: 0%;
  left: 50%;
  height: 2em;
  width: 2em;
  font-size: 2em;
}

.lightbox-left-btn {
  position: absolute;
  bottom: 50%;
  left: 1%;
  height: 2em;
  width: 2em;
  font-size: 2em;
}

.lightbox-right-btn {
  position: absolute;
  bottom: 50%;
  right: -1%;
  height: 2em;
  width: 2em;
  font-size: 2em;
}

.lightbox-image {
  width: auto;
  max-height: 90vh;
}

.lightbox-icon {
  color: white;
}


/* Mobile  */

@media (max-width: 600px) {
  .enter-btn {
    left: 35%;
  }

  .gallery p {
    font-size: 1em;
  }

  .lightbox-close-btn {
    left: 45%;
    bottom: 10%;
  }

  .lightbox-image {
    height: auto;
    max-width: 90vw;
  }

  .contact-form {
    height: 110vh;
    opacity: 0.7;
  }

  .contact-container {
    opacity: 1;
  }
}

/* Landscape */
@media (max-height: 415px) {
  .lightbox-close-btn {
    bottom: 3dvh;
  }
}